<template>
  <EzNewProjectSelector @selected="onProjectChange"></EzNewProjectSelector>
  <section v-if="projectName">
    Could be a file selector here, but for now just a button:
    <button @click="onConvertClick()">Convert {{projectName}}</button>
    <div v-if="isConverting">
      Converting...
      <progress class="progress is-small is-primary" max="100">15%</progress>
    </div>
    <div class="notification is-primary" v-if="done">
      Done
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { convertService } from '@/services/convert.service';
import EzNewProjectSelector from '@/components/newProjectSelector.vue';

interface Project {
  id: number;
  name: string;
}

@Options({
  components: {
    EzNewProjectSelector,
  },
})
export default class PacksPage extends Vue {
  projects: Project[] | null = null;

  projectName = '';

  isConverting = false;

  done = false;

  onProjectChange(projectId: string): void {
    this.projectName = projectId;
  }

  async onConvertClick(): Promise<void> {
    this.done = false;
    this.isConverting = true;
    await convertService.convert(this.projectName);
    this.isConverting = false;
    this.done = true;
  }
}
</script>
