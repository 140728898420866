<template>
  <select @change="onProjectChange($event)" >
    <option value="">Select project</option>
    <option v-for="(project, index) in projects" v-bind:key="index" v-bind:value="project">
      {{ project }}
    </option>
  </select>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { projectService } from '@/services/project.service';

@Options({
  emits: ['selected'],
})
export default class EzNewProjectSelector extends Vue {
  projects: string[] | null = null;

  initialised = false;

  async created(): Promise<void> {
    this.projects = await projectService.getProjectsToImport();
    this.initialised = true;
  }

  onProjectChange(event: any): void {
    this.$emit('selected', event.target.value);
  }
}
</script>
